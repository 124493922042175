import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { ExpertFilesModule } from '@techspert-io/expert-files';
import { ExpertsModule } from '@techspert-io/experts';
import { OpportunitiesModule } from '@techspert-io/opportunities';
import { SurveysModule } from '@techspert-io/surveys';
import { NgxFileDropModule } from 'ngx-file-drop';
import { BtxSharedModule } from '../../../../shared/btx-shared.module';
import { MaterialModule } from '../../../../shared/patterns/material-module';
import { SearchModule } from '../../../search/search.module';
import { ExpertCloneDirective } from './expert-clone/expert-clone.directive';
import { OpportunityActionsMenuComponent } from './opportunity-actions-menu/opportunity-actions-menu.component';
import { AvailabilityBoxComponent } from './opportunity-expert-list/expert/availability-box/availability-box.component';
import { CountryBoxComponent } from './opportunity-expert-list/expert/country-box/country-box.component';
import { EmailBoxComponent } from './opportunity-expert-list/expert/email-box/email-box.component';
import { ExpertComponent } from './opportunity-expert-list/expert/expert.component';
import { ScheduledBoxReminderComponent } from './opportunity-expert-list/expert/scheduled-box/scheduled-box-reminder/scheduled-box-reminder.component';
import { ScheduledBoxComponent } from './opportunity-expert-list/expert/scheduled-box/scheduled-box.component';
import { ScreenerBoxComponent } from './opportunity-expert-list/expert/screener-box/screener-box.component';
import { OpportunityExpertListComponent } from './opportunity-expert-list/opportunity-expert-list.component';
import { OpportunityResultsComponent } from './opportunity-results.component';
import { OpportunitySearchSelectDialogComponent } from './opportunity-search-select-dialog/opportunity-search-select-dialog.component';
import { OpportunityTopBarComponent } from './opportunity-top-bar/opportunity-top-bar.component';
import { ExpertRespondedDetailComponent } from './responded-dialog/expert-responded-detail/expert-responded-detail.component';
import { RespondedDialogComponent } from './responded-dialog/responded-dialog.component';
import { SearchDialogComponent } from './search-dialog/search-dialog.component';
import { SolicitationModule } from './send-solicitation-dialog/solicitation.module';
import { OpportunityExpertsEffects } from './services/opportunity-experts.effects';

@NgModule({
  declarations: [
    OpportunityResultsComponent,
    OpportunityTopBarComponent,
    OpportunityExpertListComponent,
    ExpertComponent,
    EmailBoxComponent,
    CountryBoxComponent,
    AvailabilityBoxComponent,
    ScheduledBoxComponent,
    ScheduledBoxReminderComponent,
    RespondedDialogComponent,
    ExpertRespondedDetailComponent,
    SearchDialogComponent,
    ExpertCloneDirective,
    OpportunityActionsMenuComponent,
    ScreenerBoxComponent,
    OpportunitySearchSelectDialogComponent,
  ],
  imports: [
    BtxSharedModule,
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    SolicitationModule,
    MatTreeModule,
    SearchModule,
    MaterialModule,
    NgxFileDropModule,
    ExpertsModule,
    SurveysModule,
    ExpertFilesModule,
    OpportunitiesModule,
    EffectsModule.forFeature(OpportunityExpertsEffects),
  ],
  exports: [
    OpportunityResultsComponent,
    OpportunityTopBarComponent,
    OpportunityExpertListComponent,
    ExpertComponent,
    EmailBoxComponent,
    CountryBoxComponent,
    ScheduledBoxComponent,
    ScheduledBoxReminderComponent,
    AvailabilityBoxComponent,
    SearchDialogComponent,
  ],
})
export class OpportunityModule {}
