import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MaterialModule } from '../../shared/patterns/material-module';
import { FileStoreModule } from '../file-store/file-store.module';
import { ExpertFileEditDialogComponent } from './components/expert-file-edit-dialog/expert-file-edit-dialog.component';
import { ExpertFilesComponent } from './components/expert-files/expert-files.component';
import { ExpertFileService } from './services/expert-file.service';
import { ExpertFilesStateService } from './services/expert-files-state.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxFileDropModule,
    ReactiveFormsModule,
    MaterialModule,
    FileStoreModule,
  ],
  declarations: [ExpertFilesComponent, ExpertFileEditDialogComponent],
  providers: [ExpertFileService, ExpertFilesStateService],
  exports: [ExpertFilesComponent],
})
export class ExpertFilesModule {}
