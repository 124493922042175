<div class="opp-topbar" *ngIf="client && opportunity">
  <div class="opp-topbar-row">
    <div class="opp-topbar-row-content" data-test-id="opportunity-results-topbar">
      <mat-icon class="back-arrow" [routerLink]="['/admin', 'clients', client.clientId]">
        keyboard_backspace
      </mat-icon>
      <span class="route-text" data-test-id="opportunity-results-topbar-client-name">{{ client.clientName }}</span>
      <mat-icon class="chevron">chevron_right</mat-icon>
      <span class="route-text" data-test-id="opportunity-results-topbar-opportunity-name">
        {{ opportunity | opportunityName }}</span>
      <mat-icon class="chevron">chevron_right</mat-icon>
      <div class="thin-button" [class.filtered]="hasFilter" (click)="showSearchesSelect()">
        <span>Select searches</span>
      </div>
      <ng-container *ngIf="showLoader">
        <mat-icon class="loading">
          sync
        </mat-icon>
      </ng-container>
      <ng-container *ngIf="!showLoader">
        <div class="action-buttons" data-test-id="opportunity-results-topbar-expert-count">
          Total: {{ experts.length }}
        </div>
        <span> / </span>
        <div class="action-buttons" data-test-id="opportunity-results-topbar-expert-total-count">
          {{ expertsTotal$ | async }}
        </div>
      </ng-container>
    </div>
    <app-opportunity-actions-menu class="opp-topbar-row-content" [experts]="experts" [opportunity]="opportunity"
      (openSearch)="openSearch()">
    </app-opportunity-actions-menu>
  </div>
  <app-opportunity-top-bar [activePhase]="selectedPhase" [selectedSearches]="selectedSearches"
    (selectedPhase)="selectPhase($event)" data-test-id="opportunity-results-opportunity-top-bar">
  </app-opportunity-top-bar>
  <div class="opp-topbar-row">
    <div class="opp-topbar-row-content flex flex-row gap-[15px]">
      <span class="master-check-box" (click)="selectAllExperts()">
        <mat-icon *ngIf="!selectAllToggle">check_box_outline_blank</mat-icon>
        <mat-icon *ngIf="selectAllToggle" class="checked">check_box</mat-icon> All
      </span>
      <div class="ct-count">
        <mat-icon class="ct-count__icon">person</mat-icon>
        <span class="ct-count__value" data-test-id="opportuntity-results-count-value">{{ filteredExperts.length
          }}</span>
      </div>
      <div class="search-input">
        <mat-icon class="search-input-icon">search</mat-icon>
        <input [(ngModel)]="searchTerm" (keyup)="applyFilter()" class="search-input-input" type="text"
          placeholder="Search experts..." data-test-id="opportunity-results-search-experts" />
      </div>
      <div class="no-emails-toggle" title="Filter emails" (click)="applyFilterEmails()"
        data-test-id="opportunity-results-hide-no-email">
        <mat-icon *ngIf="!filterEmails">check_box_outline_blank</mat-icon>
        <mat-icon *ngIf="filterEmails" class="checked">check_box</mat-icon>
        <p>Hide without valid emails</p>
      </div>
      <div class="no-emails-toggle" *ngIf="hasAutomatedExperts" title="Filter Auto" (click)="applyFilterAutoExperts()">
        <mat-icon *ngIf="!filterAutoExperts">check_box_outline_blank</mat-icon>
        <mat-icon *ngIf="filterAutoExperts" class="checked">check_box</mat-icon>
        <p>Hide automated</p>
      </div>
    </div>
    <div class="opp-topbar-row-content">

      <!-- Bulk reject/unreject button div -->

      <button class="thin-button" [disabled]="!selectedExpertsCount"
        *ngIf="selectedPhase === 'identified' || selectedPhase === 'firstFollowUp' || selectedPhase === 'secondFollowUp' || selectedPhase === 'outreachComplete'"
        (click)="rejectExperts()" title="Reject experts" data-test-id="opportunity-results-reject">
        <mat-icon class="thin-button-icon">thumb_down</mat-icon>
        <span class="selected-span" data-test-id="opportunity-results-reject-label">
          {{ selectedExpertsCount }}
        </span>
      </button>
      <button class="thin-button" [disabled]="!selectedExpertsCount" *ngIf="selectedPhase === 'rejected'"
        (click)="unrejectExperts()" title="Unreject experts" data-test-id="opportunity-results-unreject">
        <mat-icon class="thin-button-icon">thumb_up</mat-icon>
        <span class="selected-span" data-test-id="opportunity-results-unreject-label">
          {{ selectedExpertsCount }}
        </span>
      </button>

      <!-- Backward button div -->

      <button *ngIf="selectedPhase !== 'identified' && selectedPhase !== 'outreach'"
        (click)="handlePhaseMenuSelect('previous')" (contextmenu)="backwardRightClick($event); $event.preventDefault();"
        title="Move back" class="thin-button" [disabled]="isBackButtonDisabled()"
        data-test-id="opportunity-results-move-experts-backward">
        <mat-icon style="transform: scale(0.85);" class="thin-button-icon">west</mat-icon>
        <span class="selected-span" data-test-id="opportunity-results-move-experts-backward-label">
          {{ selectedExpertsCount }}
        </span>
      </button>

      <!-- Forward button div -->

      <!-- && selectedPhase !== 'secondFollowUp' -->
      <button class="thin-button" [disabled]="!selectedExpertsCount"
        *ngIf="selectedPhase !== 'completed' && selectedPhase !== 'outreachComplete' && selectedPhase !== ''"
        (click)="handlePhaseMenuSelect('next')" (contextmenu)="forwardRightClick($event); $event.preventDefault();"
        title="Move forward" data-test-id="opportunity-results-move-experts-forward">
        <mat-icon style="transform: scale(0.85);" class="thin-button-icon">east</mat-icon>
        <span class="selected-span" data-test-id="opportunity-results-move-experts-forward-label">
          {{ selectedExpertsCount }}
        </span>
      </button>

      <!-- Responded Experts div in 'identified', 'firstFollowUp', 'secondFollowUp' stages with how_to_reg icon-->

      <button class="thin-button" [disabled]="!selectedExpertsCount"
        *ngIf="selectedPhase === 'identified' || selectedPhase === 'firstFollowUp' || selectedPhase === 'secondFollowUp'"
        (click)="openRespondedDialog('outreach')" title="Responded experts"
        data-test-id="opportunity-move-expert-to-responded">
        <mat-icon style="transform: scale(0.84);" class="thin-button-icon">how_to_reg</mat-icon>
        <span class="selected-span" data-test-id="opportunity-move-expert-to-responded-label">
          {{ selectedExpertsCount }}
        </span>
      </button>

      <!-- Responded Experts div in 'outreachComplete' stage with forward arrow icon-->

      <button class="thin-button" [disabled]="!selectedExpertsCount" *ngIf="selectedPhase === 'outreachComplete'"
        (click)="selectedExpertsCount ? openRespondedDialog('outreach') : ''" title="Responded experts"
        (contextmenu)="forwardRightClick($event); $event.preventDefault();"
        data-test-id="opportunity-results-move-experts-forward">
        <mat-icon style="transform: scale(0.85);" class="thin-button-icon">east</mat-icon>
        <span class="selected-span" data-test-id="opportunity-results-move-experts-forward-label">
          {{ selectedExpertsCount }}
        </span>
      </button>

      <button class="thin-button" (click)="openEmailDialog()" title="Send email"
        [disabled]="!selectedExpertsCount || selectedPhase === 'rejected'" data-test-id="opportunity-results-email">
        <mat-icon style="transform: scale(0.72);" class="thin-button-icon">mail_outline</mat-icon>
        <span class="selected-span" data-test-id="opportunity-results-email-label">
          {{ selectedExpertsCount }}
        </span>
      </button>
    </div>
  </div>
</div>
<div class="clear"> </div>

<app-opportunity-expert-list *ngIf="!showLoader; else isLoadingTmpl" [experts]="filteredExperts" [phase]="selectedPhase"
  (toggleAdminRejectStatusSignal)="toggleAdminRejectedExpertStatus($event)" (selectExpertSignal)="selectExpert($event)"
  (toggleBlockExpertSignal)="toggleBlockExpert($event)" [openFilter]="openFilter"
  (navigateToExpertSignal)="navigateToExpert($event)" [showAffiliations]="showAffiliationsToggle"
  (addExpertiseToSearchSignal)="addExpertiseToSearch($event)"
  (showScreenerResultsSignal)="showExpertScreenerResult($event)">
</app-opportunity-expert-list>

<div class="no-experts-message" *ngIf="showNoExperts">
  <h2>No Experts</h2>
  <i class="material-icons people-icon">people</i>
</div>

<ng-template #isLoadingTmpl>
  <div class="expert-list-loader">
    <app-scintillating-loader></app-scintillating-loader>
  </div>
</ng-template>

<ng-template #phaseMenu let-clickDirection="action">
  <section class="phase-menu" data-test-id="opportunity-phase-menu">
    <li *ngFor="let phase of eligiblePhaseMenuOptions"
      (click)="selectedExpertsCount ? handlePhaseMenuSelect(clickDirection, phase.key) : closePhaseMenuOverlay()">
      {{phase.value}}
    </li>
  </section>
</ng-template>