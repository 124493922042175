import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { IOpportunity } from '@techspert-io/opportunities';
import { StatisticsService } from '../../services/statistics.service';

@Component({
  selector: 'app-opportunity-tile',
  templateUrl: './opportunity-tile.component.html',
  styleUrls: ['./opportunity-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpportunityTileComponent implements OnChanges {
  @Input() opportunity: IOpportunity;
  @Input() showDetailsDialogButton = true;

  opportunityClosedConnections = 0;
  opportunityOpenConnections = 0;
  opportunityUrl: string[];
  opportunityDashboardUrl: string[];

  get opportunityName(): string {
    const prefix = this.opportunity.omnisearchHidden ? 'HIDDEN: ' : '';
    const postfix = this.opportunity.onHold ? ' [ON-HOLD]' : '';
    return `${prefix}${this.opportunity.opportunityName}${postfix}`;
  }

  constructor(private statisticsService: StatisticsService) {}

  ngOnChanges(): void {
    this.opportunityDashboardUrl = [
      '/',
      'admin',
      'client',
      this.opportunity.clientId,
      'opportunity',
      this.opportunity.opportunityId,
      'dashboard',
    ];

    this.opportunityUrl = [
      '/',
      'admin',
      'client',
      this.opportunity.clientId,
      'opportunity',
      this.opportunity.opportunityId,
      'search',
      'all-searches',
      'phase',
      'identified',
    ];

    this.opportunityOpenConnections =
      this.statisticsService.opportunityCalculator(
        'open-connections',
        this.opportunity
      );
    this.opportunityClosedConnections =
      this.statisticsService.opportunityCalculator(
        'closed-connections',
        this.opportunity
      );
  }
}
