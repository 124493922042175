import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IUserBase } from '@techspert-io/auth';
import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';
import {
  IClientContactDialogData,
  IOpportunityClientContact,
} from '../../../models/opportunity.models';
import { ClientContactDialogComponent } from '../../dialogs/client-contact-dialog/client-contact-dialog.component';

@Component({
  selector: 'opp-client-contacts',
  templateUrl: './client-contacts.component.html',
  styleUrls: ['./client-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientContactsComponent {
  @Input() clientContacts: IOpportunityClientContact[];
  @Input() sendInviteEmail: boolean = true;
  @Output() upsert = new EventEmitter<IUserBase>();
  @Output() delete = new EventEmitter<IOpportunityClientContact>();
  @Output() setPrimary = new EventEmitter<IOpportunityClientContact>();

  clientContactsColumns = ['name', 'email', 'country', 'timezone'];
  clientContactsCells = [...this.clientContactsColumns, 'select', 'options'];

  clientContactSelection = new SelectionModel<IUserBase>(true, []);

  constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef) {}

  upsertItem(data?: IUserBase | IUserBase[]): void {
    const adjustedData = this.adjustInviteForType(data);

    this.openClientContactsModal(adjustedData)
      .pipe(
        tap((updatedFields) => {
          const selectedContacts = this.clientContactSelection.selected.reduce(
            (prev, curr) => ({
              ...prev,
              [curr.connectId]: {
                ...curr,
                ...updatedFields,
                connectId: curr.connectId,
              },
            }),
            {}
          );
          this.updateClientContacts((contact) => ({
            ...contact,
            ...(selectedContacts[contact.connectId] || {}),
          }));
        })
      )
      .subscribe((item) => {
        this.clientContactSelection.clear();
        this.cdr.detectChanges();

        for (const key in item) {
          this.upsert.emit(item[key]);
        }
      });
  }

  private updateClientContacts(
    mapFn: (contact: IOpportunityClientContact) => IOpportunityClientContact
  ): void {
    this.clientContacts = this.clientContacts.map(mapFn);
  }

  private openClientContactsModal(data?: IUserBase[]): Observable<IUserBase> {
    return this.dialog
      .open<ClientContactDialogComponent, IClientContactDialogData, IUserBase>(
        ClientContactDialogComponent,
        {
          width: '840px',
          data: { data },
        }
      )
      .afterClosed()
      .pipe(takeWhile((d) => !!d));
  }

  deleteItem(data: IOpportunityClientContact): void {
    this.delete.emit(data);
  }

  setPrimaryItem(data: IOpportunityClientContact): void {
    this.setPrimary.emit(data);
  }

  private adjustInviteForType(users: IUserBase | IUserBase[]): IUserBase[] {
    const userArray = Array.isArray(users) ? users : [users];

    return userArray.map((user) => ({
      ...user,
      options: {
        ...user?.options,
        sendInviteEmail: user?.options?.sendInviteEmail ?? this.sendInviteEmail,
        hideInviteToggle:
          this.sendInviteEmail !== undefined
            ? false
            : user?.connectId === undefined
            ? false
            : true,
      },
    }));
  }
}
