/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store, createSelector } from '@ngrx/store';
import {
  ClientActions,
  IClient,
  selectCurrentClient,
} from '@techspert-io/clients';
import {
  ExpertCallActionService,
  IExpertCallAction,
} from '@techspert-io/conferences';
import { IEngagement } from '@techspert-io/engagements';
import { ExpertActionStatusMap } from '@techspert-io/expert-actions';
import {
  ExpertProfileActions,
  IExpertProfile,
  selectCurrentExpertProfile,
} from '@techspert-io/expertProfiles';
import {
  ExpertActions,
  IExpert,
  selectCurrentExpert,
} from '@techspert-io/experts';
import {
  IOpportunity,
  OpportunityConferenceProviders,
  selectCurrentOpportunity,
} from '@techspert-io/opportunities';
import { ToastService } from '@techspert-io/user-alerts';
import * as Moment from 'moment';
import { EMPTY, Observable, Subject, combineLatest, merge } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { ScheduleComponent } from '../../../shared/components/schedule/schedule.component';
import { MessageStatusMap } from '../../../shared/models/email';
import {
  ActionEmailType,
  ConferenceService,
} from '../../../shared/services/conference.service';
import {
  IScheduleDialogClosePayload,
  IScheduleDialogInput,
  scheduleDialogSize,
} from '../schedule/schedule-models';
import { UpdateEmergencyContactComponent } from '../update-emergency-contact/update-emergency-contact.component';

export interface IViewConnectionInput {
  callAction: IExpertCallAction;
  isActionsDisabled?: boolean;
}
export interface IViewConnectionDialogClosePayload {
  callAction: IExpertCallAction;
  engagement?: IEngagement;
}

export const viewConnectionDialogSize = {
  width: '600px',
  height: '804px',
};

const selectConnectionData = createSelector(
  selectCurrentClient,
  selectCurrentOpportunity,
  selectCurrentExpert,
  (client, opportunity, expert) => ({ client, opportunity, expert })
);

interface ITableItem {
  date: number;
  status: MessageStatusMap;
  statusLabel: string;
  statusIcon: string;
  statusClass: string;
  recipient: string;
  emailType: ActionEmailType;
}

@Component({
  selector: 'app-view-connection',
  templateUrl: './view-connection.component.html',
  styleUrls: ['./view-connection.component.scss'],
})
export class ViewConnectionComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  moment = Moment;
  expert: IExpert;
  expertProfile: IExpertProfile;
  client: IClient;
  opportunity: IOpportunity;
  showCompleteStatuses = [
    ExpertActionStatusMap.Request,
    ExpertActionStatusMap.Pending,
    ExpertActionStatusMap.Complete,
  ];
  showRescheduleStatuses = [
    ExpertActionStatusMap.Pending,
    ExpertActionStatusMap.Complete,
  ];
  showCancelStatuses = [
    ExpertActionStatusMap.Complete,
    ExpertActionStatusMap.Pending,
    ExpertActionStatusMap.Request,
  ];

  OpportunityConferenceProviders = OpportunityConferenceProviders;

  dataSource = new MatTableDataSource<ITableItem>([]);
  isLoading = true;
  displayedColumns = ['date', 'recipient', 'emailType', 'statusLabel'];

  private destroy$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IViewConnectionInput,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<
      ViewConnectionComponent,
      IViewConnectionDialogClosePayload
    >,
    private conferenceService: ConferenceService,
    private expertCallActionService: ExpertCallActionService,
    private toastService: ToastService,
    private store: Store
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(
      ClientActions.fetchClient({
        clientId: this.data.callAction.clientId,
      })
    );
    this.store.dispatch(
      ExpertActions.fetchExpert({
        expertId: this.data.callAction.expertId,
      })
    );

    merge(
      combineLatest([
        this.store.select(selectConnectionData),
        this.getTableData(),
      ]).pipe(
        tap(([{ expert, client, opportunity }, tableData]) => {
          this.expert = expert;
          this.client = client;
          this.opportunity = opportunity;

          this.setTableData(tableData);
        }),
        tap(([{ expert }]) =>
          this.store.dispatch(
            ExpertProfileActions.fetchExpertProfile({
              expertProfileId: expert.expertProfileId,
            })
          )
        )
      ),
      this.store.select(selectCurrentExpertProfile).pipe(
        tap((expertProfile) => (this.expertProfile = expertProfile)),
        tap(() => (this.isLoading = false))
      )
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public updateConference(action: IScheduleDialogInput['action']): void {
    const data: IScheduleDialogInput = {
      expert: this.expert,
      expertProfile: this.expertProfile,
      opportunity: this.opportunity,
      client: this.client,
      callAction: this.data.callAction,
      action,
    };

    this.dialog
      .open<
        ScheduleComponent,
        IScheduleDialogInput,
        IScheduleDialogClosePayload
      >(ScheduleComponent, {
        ...scheduleDialogSize,
        data: data,
      })
      .afterClosed()
      .pipe(
        takeWhile((payload) => !!payload),
        switchMap((payload: IScheduleDialogClosePayload) => {
          this.dialogRef.close({
            callAction: payload.callAction,
            engagement: payload.engagement,
          });

          return EMPTY;
        })
      )
      .subscribe();
  }

  openUpdateEmergencyContact(): void {
    this.dialog
      .open<UpdateEmergencyContactComponent, string>(
        UpdateEmergencyContactComponent,
        {
          data: this.data.callAction.emergencyContact,
          width: '450px',
          height: '260px',
        }
      )
      .afterClosed()
      .pipe(
        takeWhile((contact) => !!contact),
        switchMap((contact) =>
          this.expertCallActionService.patch({
            expertActionId: this.data.callAction.expertActionId,
            emergencyContact: contact,
          })
        ),
        tap((callAction) => (this.data.callAction = callAction)),
        tap(() =>
          this.toastService.sendMessage('Updated emergency contact', 'success')
        ),
        catchError(() => {
          this.toastService.sendMessage(
            'Error occurred when updating emergency contact',
            'error'
          );
          return EMPTY;
        })
      )
      .subscribe();
  }

  mapActionStatus(status: ExpertActionStatusMap): string {
    const statusMap: Record<ExpertActionStatusMap, string> = {
      [ExpertActionStatusMap.CompleteConfirmed]: 'Completed Confirmed',
      [ExpertActionStatusMap.RequestCancelled]: 'Cancelled',
      [ExpertActionStatusMap.Cancelled]: 'Cancelled',
      [ExpertActionStatusMap.Complete]: 'Completed',
      [ExpertActionStatusMap.Pending]: 'Scheduled',
      [ExpertActionStatusMap.Request]: 'Requested',
    };
    return statusMap[status] || 'Unknown';
  }

  public closeDialog(): void {
    this.dialog.closeAll();
  }

  private getTableData(): Observable<ITableItem[]> {
    const unsubscribed = {
      statusLabel: 'Recipient unsubscribed',
      statusIcon: 'block',
      statusClass: 'fail',
    };
    const domainBlocked = {
      statusLabel: 'Recipient domain blocked',
      statusIcon: 'block',
      statusClass: 'fail',
    };
    const messageBlocked = {
      statusLabel: 'Blocked',
      statusIcon: 'block',
      statusClass: 'fail',
    };
    const permissionRejected = {
      statusLabel: 'Recipient failed validation checks',
      statusIcon: 'block',
      statusClass: 'fail',
    };
    const sendError = {
      statusLabel: 'Send error',
      statusIcon: 'block',
      statusClass: 'fail',
    };
    const sendPending = {
      statusLabel: 'Sending',
      statusIcon: 'refresh',
      statusClass: 'pending',
    };
    const messageDelivered = {
      statusLabel: 'Delivered',
      statusIcon: 'done',
      statusClass: 'success',
    };
    const statusUnknown = {
      statusLabel: 'Unknown',
      statusIcon: 'question_mark',
      statusClass: 'unknown',
    };

    const statusToDisplay: Record<
      MessageStatusMap | 'unknown',
      { statusLabel: string; statusIcon: string; statusClass: string }
    > = {
      [MessageStatusMap.connectUnsubscribed]: unsubscribed,
      [MessageStatusMap.connectBlockedDomain]: domainBlocked,
      [MessageStatusMap.connectBounced]: messageBlocked,
      [MessageStatusMap.connectPermissionRejected]: permissionRejected,
      [MessageStatusMap.connectSendFailure]: sendError,
      [MessageStatusMap.connectDeferred]: sendPending,
      [MessageStatusMap.connectSendElapsed]: sendError,
      [MessageStatusMap.connectSent]: sendPending,
      [MessageStatusMap.providerProcessing]: sendPending,
      [MessageStatusMap.providerDropped]: messageBlocked,
      [MessageStatusMap.providerDeferred]: sendPending,
      [MessageStatusMap.providerBounce]: messageBlocked,
      [MessageStatusMap.providerDelivered]: messageDelivered,
      [MessageStatusMap.providerOpen]: messageDelivered,
      [MessageStatusMap.providerClick]: messageDelivered,
      [MessageStatusMap.providerSpam]: messageDelivered,
      [MessageStatusMap.providerUnsubscribe]: messageDelivered,
      [MessageStatusMap.providerResubscribe]: messageDelivered,
      [MessageStatusMap.userUnsubscribe]: messageDelivered,
      [MessageStatusMap.connectError]: sendError,
      unknown: statusUnknown,
    };

    return this.conferenceService
      .getEmailsForCallActionId(this.data.callAction.expertActionId)
      .pipe(
        map((emails) =>
          emails.map((e) => ({
            emailType: e.actionMessage.type,
            date: e.actionMessage.dateCreated * 1000,
            status: e.message?.status,
            recipient: e.message?.recipient,
            ...statusToDisplay[e.message?.status || 'unknown'],
          }))
        ),
        catchError((error) => {
          console.error(error);
          return EMPTY;
        })
      );
  }

  private setTableData(tableData: ITableItem[]): void {
    if (tableData) {
      this.dataSource.data = tableData;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }
}
