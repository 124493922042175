import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { MaterialModule } from '../../shared/patterns/material-module';
import { CountryService } from '../../shared/services/country.service';
import { ClientHostedURLFormComponent } from '../opportunity-kick-off/components/forms/client-hosted-url-form/client-hosted-url-form.component';
import { ClientContactDialogComponent } from './components/dialogs/client-contact-dialog/client-contact-dialog.component';
import { UsersDialogComponent } from './components/dialogs/users-dialog/users-dialog.component';
import { ClientContactFormComponent } from './components/forms/client-contact-form/client-contact-form.component';
import { ReferralFeeFormComponent } from './components/forms/referral-fee-form/referral-fee-form.component';
import { SegmentOwnersFormComponent } from './components/forms/segment-owners-form/segment-owners-form.component';
import { SettingsCombinedFormComponent } from './components/forms/settings-combined-form/settings-combined-form.component';
import { SettingsFormInputComponent } from './components/forms/settings-form-input/settings-form-input.component';
import { SettingsFormComponent } from './components/forms/settings-form/settings-form.component';
import { SurveySettingsFormComponent } from './components/forms/survey-settings-form/survey-settings-form.component';
import { UsersFormComponent } from './components/forms/users-form/users-form.component';
import { ClientContactsInputComponent } from './components/layout/client-contacts-input/client-contacts-input.component';
import { ClientContactsComponent } from './components/layout/client-contacts/client-contacts.component';
import { PodMembersInputComponent } from './components/layout/pod-members-input/pod-members-input.component';
import { PodMembersComponent } from './components/layout/pod-members/pod-members.component';
import { ClientContactColumnPipe } from './pipes/client-contact-column.pipe';
import { OpportunityNamePipe } from './pipes/opportunity-name.pipe';
import { PodMembersColumnPipe } from './pipes/pod-members-column.pipe';
import { PORTAL_URL, PortalLinksPipe } from './pipes/portal-links.pipe';
import { OpportunitiesEffects } from './reducers/opportunities.effects';
import { opportunitiesReducer } from './reducers/opportunities.reducer';

const declarations = [
  ClientContactFormComponent,
  ClientContactDialogComponent,
  UsersDialogComponent,
  UsersFormComponent,
  ClientContactColumnPipe,
  OpportunityNamePipe,
  PortalLinksPipe,
  PodMembersColumnPipe,
  SettingsFormComponent,
  SettingsFormInputComponent,
  PodMembersComponent,
  PodMembersInputComponent,
  ClientContactsComponent,
  ClientContactsInputComponent,
  SegmentOwnersFormComponent,
  SettingsCombinedFormComponent,
  SurveySettingsFormComponent,
  ReferralFeeFormComponent,
  ClientHostedURLFormComponent,
];

@NgModule({
  declarations: declarations,
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    StoreModule.forFeature('opportunities', opportunitiesReducer),
    EffectsModule.forFeature(OpportunitiesEffects),
    MatExpansionModule,
  ],
  exports: [MaterialModule, ...declarations],
  providers: [
    CountryService,
    {
      provide: PORTAL_URL,
      useValue: environment.portalBase,
    },
  ],
})
export class OpportunitiesModule {}
