import { Component } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import {
  OpportunityConferenceProviderOptionLabels,
  OpportunityConferenceProviders,
} from '../../../models/opportunity.models';

@Component({
  selector: 'opp-settings-combined-form',
  templateUrl: './settings-combined-form.component.html',
  styleUrls: ['./settings-combined-form.component.scss'],
})
export class SettingsCombinedFormComponent {
  OpportunityConferenceProviders = OpportunityConferenceProviders;
  OpportunityConferenceProviderOptionLabels =
    OpportunityConferenceProviderOptionLabels;
  conferenceProviderOptions = Object.values(OpportunityConferenceProviders);

  get settingsForm(): FormGroup {
    return this.rootFormGroup.form;
  }

  constructor(private rootFormGroup: FormGroupDirective) {}
}
