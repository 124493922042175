<div class="flex flex-col gap-5 flex-1 h-full">
  <mat-card *ngIf="showSelectOptions" style="padding: 10px 24px;">
    <div class="flex justify-between content-center items-center w-full flex-row">
      <div class="flex justify-start content-center items-center h-full flex-row gap-2.5">
        <mat-checkbox [(ngModel)]="selectAllToggle" (ngModelChange)="emitToggleSelectAll()">
        </mat-checkbox>

        <div class="ct-count">
          <mat-icon class="ct-count__icon">person</mat-icon>
          <span class="ct-count__value" data-test-id="results-display-count-value">
            {{selectedCount || 0}} / {{totalCount || 0}}
          </span>
        </div>

        <div class="search-input">
          <mat-icon class="search-input-icon">search</mat-icon>
          <input (keyup)="emitFilterTerm($event)" type="text" class="search-input-input"
            data-test-id="results-display-filter-terms" />
        </div>
      </div>
      <div class="flex justify-start content-center items-center h-full flex-row gap-[5px]">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
          data-test-id="results-display-options">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="emitDownloadExpertListCsv()" mat-menu-item data-test-id="results-display-download-csv">
            <span>Download to CSV</span>
          </button>
        </mat-menu>
        <button class="button button--primary" (click)="emitSendExpertsDialog()"
          data-test-id="results-display-continue">Continue</button>
      </div>
    </div>
  </mat-card>

  <mat-card style="padding: 0; overflow: hidden;" class="flex flex-col basis-full">

    <mat-table [dataSource]="dataSource" [trackBy]="trackBy" class="ct-search-table basis-full">

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let expert" class="flex flex-row gap-5">
          <mat-checkbox *ngIf="showSelectOptions" [checked]="expert.selected" (change)="emitToggleSelect(expert.id)">
          </mat-checkbox>
          <span>{{ expert.source | expertNameSalesMode:maskedMode }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="affiliation">
        <mat-header-cell *matHeaderCellDef> Affiliation </mat-header-cell>
        <mat-cell *matCellDef="let expert">
          <ul style="padding: 10px 10px 10px 20px;" class="ct-search-table__cell--small-text flex flex-col gap-[5px]">
            <li *ngFor="let affiliation of expert.source.affiliations">{{affiliation}}</li>
          </ul>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expertise">
        <mat-header-cell *matHeaderCellDef> Expertise </mat-header-cell>
        <mat-cell *matCellDef="let expert">
          <ul class="flex flex-row flex-wrap gap-[5px]">
            <li class="ct-generic-chip ct-generic-chip--small" *ngFor="let area of expert.source.expertise">
              <span class="ct-generic-chip__text">{{area}}</span>
            </li>
          </ul>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef> Role </mat-header-cell>
        <mat-cell *matCellDef="let expert">
          <ul *ngIf="expert.roles" class="flex flex-row flex-wrap gap-[5px]">
            <li class="ct-generic-chip ct-generic-chip--small" *ngFor="let area of expert.roles">
              <span class="ct-generic-chip__text">{{area.name}}</span>
            </li>
          </ul>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="emails">
        <mat-header-cell *matHeaderCellDef> Emails </mat-header-cell>
        <mat-cell *matCellDef="let expert">
          <ul class="flex flex-col gap-2.5">
            <li class="ct-emailChip" *ngFor="let email of expert.source.opportunityEmails">
              <span class="ct-emailChip__text">
                {{ email | expertEmailMaskedMode:maskedMode }}
              </span>
            </li>
          </ul>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let expert">

          <button mat-icon-button [matMenuTriggerFor]="expertOptions" data-test-id="expert-options">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #expertOptions="matMenu">
            <button mat-menu-item data-test-id="copy-diagnostic-data" style="cursor: copy;" expert-diagnostic-data
              [expert-context]="expert">
              <span>Copy Diagnostic Data</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="['name', 'affiliation', 'role', 'expertise', 'emails', 'actions'], sticky: true">
      </mat-header-row>
      <mat-row *matRowDef="let expert; columns: ['name', 'affiliation', 'role', 'expertise', 'emails', 'actions']">
      </mat-row>
    </mat-table>

    <ng-container matColumnDef="emails">
      <mat-header-cell *matHeaderCellDef> Emails </mat-header-cell>
      <mat-cell *matCellDef="let expert">
        <ul class="flex flex-col gap-2.5">
          <li class="ct-emailChip" *ngFor="let email of expert.source.opportunityEmails">
            <span class="ct-emailChip__text">
              {{ email | expertEmailMaskedMode:maskedMode }}
            </span>
          </li>
        </ul>
      </mat-cell>
    </ng-container>

    <mat-paginator [pageSize]="100" [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons
      data-test-id="results-display-page-size"></mat-paginator>
  </mat-card>
</div>