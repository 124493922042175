<mat-expansion-panel class="ct-opportunity-list-item" hideToggle
  [attr.data-test-id]="'opportunity-tile-opportunity-' + opportunity.opportunityId">
  <mat-expansion-panel-header [collapsedHeight]="'70px'">
    <div class="ct-opportunity-list-item__header">
      <div class="ct-opportunity-list-item__identification">
        <div class="ct-opportunity-list-item__title">
          <span class="ct-opportunity-list-item__ellipsis-text"
            data-test-id="opportunity-tile-opportunity-name">{{opportunityName}}</span>
        </div>
        <ul class="ct-opportunity-list-item__pod-list">
          <li class="person-initials" *ngFor="let user of opportunity.opportunityAssignees">
            <span data-test-id="opportunity-tile-opportunity-assignee">
              {{user.firstName.charAt(0)}} {{user.lastName.charAt(0)}}
            </span>
          </li>
        </ul>
      </div>
      <div class="ct-opportunity-list-item__actions">
        <span matTooltip="Open opportunity details" *ngIf="showDetailsDialogButton"
          class="ct-opportunity-list-item__icon ct-icon ct-icon--selectable">
          <a [routerLink]="opportunityDashboardUrl">
            <mat-icon data-test-id="opportunity-tile-open-details">
              subject_rounded
            </mat-icon>
          </a>

        </span>
        <a matTooltip="Go to opportunity" class="ct-icon-button" [routerLink]="opportunityUrl">
          <mat-icon data-test-id="opportunity-tile-go-to-opportunity">
            arrow_forward
          </mat-icon>
        </a>
      </div>
    </div>
  </mat-expansion-panel-header>
  <p>Open connections: {{opportunityOpenConnections | number}}</p>
  <p>Completed connections: {{opportunityClosedConnections | number}}</p>
  <div class="ct-opportunity-list-item__content">
    <app-geographic-progress [opportunity]="opportunity"></app-geographic-progress>
  </div>
</mat-expansion-panel>