import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IDisplayExpert, IExpert } from '@techspert-io/experts';

@Component({
  selector: 'app-opportunity-expert-list',
  templateUrl: './opportunity-expert-list.component.html',
  styleUrls: ['./opportunity-expert-list.component.scss'],
})
export class OpportunityExpertListComponent {
  @ViewChild(CdkVirtualScrollViewport)
  virtualScroll: CdkVirtualScrollViewport;
  @Input() experts: IDisplayExpert[] = [];
  @Input() phase: string = 'identified';
  @Input() openFilter: boolean;
  @Input() showAffiliations: boolean;
  @Output() selectExpertSignal = new EventEmitter<IDisplayExpert>();
  @Output() toggleAdminRejectStatusSignal = new EventEmitter<IDisplayExpert>();
  @Output() toggleBlockExpertSignal = new EventEmitter<IDisplayExpert>();
  @Output() navigateToExpertSignal = new EventEmitter<IDisplayExpert>();
  @Output() addExpertiseToSearchSignal = new EventEmitter<string>();
  @Output() showScreenerResultsSignal = new EventEmitter<IDisplayExpert>();

  public trackByFn(_index: number, item: IExpert): string {
    return item.expertId;
  }

  public goToTop(): void {
    this.virtualScroll.scrollToIndex(0);
  }

  public emitSelectExpertSignal(expert: IDisplayExpert): void {
    this.selectExpertSignal.emit(expert);
  }

  public emitToggleBlockExpertSignal(expert: IDisplayExpert): void {
    this.toggleBlockExpertSignal.emit(expert);
  }

  public emitToggleAdminRejectStatusSignal(expert: IDisplayExpert): void {
    this.toggleAdminRejectStatusSignal.emit(expert);
  }

  public emitNavigateToExpertSignal(expert: IDisplayExpert): void {
    this.navigateToExpertSignal.emit(expert);
  }

  public emitAddExpertiseToSearchSignal(expertise: string): void {
    this.addExpertiseToSearchSignal.emit(expertise);
  }

  public emitShowScreenerResults(expert: IDisplayExpert): void {
    this.showScreenerResultsSignal.emit(expert);
  }
}
